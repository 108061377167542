var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"d-flex justify-content-between align-items-center w-100 font-weight-bold py-3 mb-1",style:({ opacity: _vm.show ? 1 : 0 })},[_c('div',[_vm._v(" Olá "+_vm._s(_vm.logged_user.name)+", "+_vm._s(!_vm.shared.activeWorkspace.none_selected ? "nós temos" : "por favor selecione um workspace")+" ")])]),_c('div',{staticClass:"float-right"},[_c('span',{staticClass:"mr-1 h4"},[_vm._v("Tarefas")]),_c('label',{staticClass:"switcher mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.load_routines),expression:"load_routines"}],staticClass:"switcher-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.load_routines)?_vm._i(_vm.load_routines,null)>-1:(_vm.load_routines)},on:{"change":[function($event){var $$a=_vm.load_routines,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.load_routines=$$a.concat([$$v]))}else{$$i>-1&&(_vm.load_routines=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.load_routines=$$c}},function($event){return _vm.refresh()}]}}),_vm._m(0)]),_c('span',{staticClass:"h4 ml-1"},[_vm._v("Rotinas")])]),(!_vm.shared.activeWorkspace.none_selected)?_c('b-row',{key:_vm.load_routines,staticClass:"mb-3"},[_c('b-col',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.window",value:('Clique para ver as tarefas vencidas'),expression:"'Clique para ver as tarefas vencidas'",modifiers:{"window":true}}],staticClass:"text-lightest",staticStyle:{"min-height":"130px","cursor":"pointer","background-color":"#dc202e","font-weight":"bold","font-size":"18px"},on:{"click":() => {
            this.help_text = `${this.load_routines ? 'Rotinas' : 'Tarefas'
              } das quais você esta envolvido que estão vencidas`;

            let today = new Date();
            _vm.findTasks({
              expected_finish_date__lt: _vm.utils.format_date(today),
              status: '0,1',
            });
          }}},[_c('b-row',[_c('b-col',[(this.load_routines)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.today ?? 0))]),_vm._v(" "+_vm._s(_vm.dashboardData.delayed.today != 1 ? `rotinas que ja venceram` : `rotina que ja venceu`)+" ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.today ?? 0))]),_vm._v(" "+_vm._s(_vm.dashboardData.delayed.today != 1 ? `tarefas que ja venceram` : `tarefa que ja venceu`)+" ")])]),_c('b-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('i',{staticClass:"display-1 far fa-chart-bar",staticStyle:{"position":"absolute","right":"0px","top":"calc(0px + 38px)"}})])])],1)],1)],1),_c('b-col',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.window",value:('Clique para ver as tarefas que estão para vencer nesta semana'
          ),expression:"'Clique para ver as tarefas que estão para vencer nesta semana'\n          ",modifiers:{"window":true}}],staticClass:"text-lightest",staticStyle:{"min-height":"130px","cursor":"pointer","background-color":"#edd818","color":"white !important","font-weight":"bold","font-size":"18px"},on:{"click":() => {
            this.help_text = `${this.load_routines ? 'Rotinas' : 'Tarefas'
              } das quais você esta envolvido que estão para vencer nesta semana`;
            let today = new Date();
            let nextWeek = new Date();
            nextWeek.setDate(today.getDate() + 7);
            _vm.findTasks({
              expected_finish_date__lte: _vm.utils.format_date(nextWeek),
              expected_finish_date__gte: _vm.utils.format_date(today),
              status: '0,1',
            });
          }}},[_c('b-row',[_c('b-col',[(this.load_routines)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.thisWeek ?? 0))]),_vm._v(" "+_vm._s(!(_vm.dashboardData.delayed.thisWeek == 1) ? "rotinas" : "rotina")+" a vencer nesta semana. ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.thisWeek ?? 0))]),_vm._v(" "+_vm._s(!(_vm.dashboardData.delayed.thisWeek == 1) ? "tarefas" : "tarefa")+" a vencer nesta semana. ")])]),_c('b-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('i',{staticClass:"display-1 far fa-chart-bar",staticStyle:{"position":"absolute","right":"0px","top":"calc(0px + 38px)"}})])])],1)],1)],1),_c('b-col',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.window",value:('Clique para ver as tarefas que estão dentro do prazo'
          ),expression:"'Clique para ver as tarefas que estão dentro do prazo'\n          ",modifiers:{"window":true}}],staticClass:"bg-success-dark text-lightest",staticStyle:{"min-height":"130px","cursor":"pointer","background-color":"#00b327 !important","color":"white !important","font-weight":"bold","font-size":"18px"},on:{"click":() => {
            this.help_text = `${this.load_routines ? 'Rotinas' : 'Tarefas'
              }s das quais você esta envolvido que estão dentro do prazo`;
            let today = new Date();
            let thisMonth = new Date();
            thisMonth.setMonth(today.getMonth() + 1);
            thisMonth.setDate(0);
            _vm.findTasks({
              expected_finish_date__lte: _vm.utils.format_date(thisMonth),
              expected_finish_date__gte: _vm.utils.format_date(today),
              status: '0,1',
            });
          }}},[_c('b-row',[_c('b-col',[(this.load_routines)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.thisMonth ?? 0))]),_vm._v(" "+_vm._s(!(_vm.dashboardData.delayed.thisMonth == 1) ? "rotinas" : "rotina")+" dentro do prazo ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.delayed.thisMonth ?? 0))]),_vm._v(" "+_vm._s(!(_vm.dashboardData.delayed.thisMonth == 1) ? "tarefas" : "tarefa")+" dentro do prazo ")])]),_c('b-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('i',{staticClass:"display-1 far fa-chart-bar",staticStyle:{"position":"absolute","right":"0px","top":"calc(0px + 38px)"}})])])],1)],1)],1),_c('b-col',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.window",value:('Clique para ver as tarefas que foram modificadas'
          ),expression:"'Clique para ver as tarefas que foram modificadas'\n          ",modifiers:{"window":true}}],staticClass:"bg-info-dark text-lightest",staticStyle:{"min-height":"130px","cursor":"pointer","background-color":"#04d5c8 !important","color":"white !important","font-weight":"bold","font-size":"17px"},on:{"click":() => {
            this.help_text =
              'Tarefas das quais você esta envolvido que foram modificadas';
            this.tasksData = this.logs.map((e) => e.object_pk);
            this.processTasksData();
          }}},[_c('b-row',[_c('b-col',[(this.load_routines)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.recent.updated ?? 0))]),_vm._v(" "+_vm._s(_vm.dashboardData.recent.updated != 1 ? `rotinas foram modificadas` : `rotina foi modificada`)+" recentemente ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"display-4"},[_vm._v(_vm._s(_vm.dashboardData.recent.updated ?? 0))]),_vm._v(" "+_vm._s(_vm.dashboardData.recent.updated != 1 ? `tarefas foram modificadas` : `tarefa foi modificada`)+" recentemente ")])]),_c('b-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('i',{staticClass:"display-1 far fa-chart-bar",staticStyle:{"position":"absolute","right":"0px","top":"calc(0px + 38px)"}})])])],1)],1)],1)],1):_vm._e(),(!_vm.shared.activeWorkspace.none_selected)?_c('DashboadCardTable',{attrs:{"tasksData":_vm.tasksData,"table_key":_vm.table_key,"help_text":_vm.help_text},model:{value:(_vm.only_users),callback:function ($$v) {_vm.only_users=$$v},expression:"only_users"}},[_vm._v(">")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"switcher-indicator",staticStyle:{"background":"rgba(24, 28, 33, 0.1)"}},[_c('span',{staticClass:"switcher-yes"}),_c('span',{staticClass:"switcher-no"})])
}]

export { render, staticRenderFns }